.App {
  background-color: white;
  text-align: center;
  z-index: 10;
}
.App-header {
  background-color: #a3abbb;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 70%;
  font-size: 1.5rem;
  color: white;
}
.indexwtv > * {
  z-index: 10;
}
.nice-button{
  z-index: 10;
  padding: 10px 30px;
  background: #3f4257;
  border-radius: 30px;
  color: white;
  font-weight: 300;
  font-size: 14px;
  margin: 0 0;
  transition: all 0.2s ease-in;
  cursor: pointer;
  outline: none;
  border: none;
}
.nice-button:hover{
  background-color: #75767b;
}

.special-projects-container{
    margin-bottom: 60px;
    display: flex;
    justify-content: space-between;
}
.special-project-description{
    color: black;

}
.special-project-description p{
    text-align: left;
}
.skills-container{
    display: flex;
    min-height: 500px;
    justify-content: center;
    align-items: center;
}
.search-box{
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 500px;
}
.chart{
    max-width: 1200px;
    max-height: 500px;
}
@media (max-width: 500px) {
    .skills-container{
        flex-direction: column;
    }
}
.projects-container{
    min-height: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
}
.project-card-section{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
}
.arrow{
    color: black;
}

.arrow:hover{
    cursor: pointer;
}
.about-me-story{
    text-align: center;
    padding: 10px 50px;
}
.about-me-container{
    padding: 0 50px 0 50px;
    margin-top: 30px;
}
.about-me-stories{
    display: flex;
    flex-wrap: wrap;
}
.about-me-rectangle{
    display: flex;
    flex-direction: column;
    background-color: beige;
    border-radius: 10px;
    margin: 10px 30px 10px 30px;
    width: 46%;
}
.about-me-rectangle img{
    max-width: 100%;
    border-radius: 10px;
    border-style: solid;
}
.about-me-description{
    background-color: antiquewhite;
    padding: 0 20px 0 20px;
    display: flex;
    flex-direction: row;
    font-size: 1.2rem;
    flex-wrap: wrap;
    min-height: 100%;
    max-width: 100%;
    font-family: Georgia, serif;
    position: relative;
    margin-bottom: 50px;
}
.about-me-description h2{
    font-weight: 400;
    font-size: 2.25rem;
    line-height: 1.1;
    margin-top: 20px;
    margin-bottom: 20px;
    font-style: italic;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
}
.about-me-description-block{
    max-width: 50%;
}